/** @format */
import {
  PLATFORM
} from 'aurelia-pal';
import { TranslationService } from 'services/translationService';
import userService from 'services/api/userService';
import './admin.scss';

export class AdminPage {
  configureRouter(config, router) {
    this.router = router;

    this.translations = router.container.get(TranslationService);
    this.translations.loadNamespaces('admin');

    this.accountName = '';
    this.userService = router.container.get(userService);

    const setupRoutes = user => {
      const routes = [];

      routes.push(this.newRoute(['', 'profile'], false, 'profile', 'Profile', PLATFORM.moduleName('pages/admin/pages/profile/admin-profile-page')));
      routes.push(this.newRoute('assets', false, 'assets', 'Assets', PLATFORM.moduleName('pages/admin/pages/assets/admin-assets-page')));
      routes.push(this.newRoute('drivers', false, 'drivers', 'Drivers', PLATFORM.moduleName('pages/admin/pages/drivers/admin-drivers-page')));

      routes.push(this.newRoute('poi', false, 'poi', 'POI', PLATFORM.moduleName('pages/admin/pages/poi/admin-poi-page')));
      routes.push(this.newRoute('alerts', false, 'alerts', 'Alerts', PLATFORM.moduleName('pages/admin/pages/alerts/admin-alerts-page')));
      routes.push(this.newRoute('reminders', false, 'reminders', 'Reminders', PLATFORM.moduleName('pages/admin/pages/reminders/admin-reminders-page')));

      routes.push(this.newRoute('users', true, null, 'Users', PLATFORM.moduleName('components/manage-users-page/manage-users-page')));
      routes.push(this.newRoute('groups', true, null, 'Groups', PLATFORM.moduleName('pages/admin/pages/groups/admin-groups-page')));

      if (
        userService.hasPermission('modules.administration') &&
        userService.hasPermission('modules.developer')
      ) {
        routes.push(this.newRoute('api', true, 'machines', 'API', PLATFORM.moduleName('pages/admin/pages/machines/admin-machines-page')));
      }

      config.map(routes);
    };

    this.userService.getSelf().then(user => {
      this.accountName = user.accountName || 'Fonix';
      setupRoutes(user);
    });

    config.mapUnknownRoutes(() => {
      //navigateToRoute not working
      this.router.navigate('admin/profile');
    });
  }

  goTo(route) {
    this.router.navigateToRoute(route);
  }

  newRoute(route, isSubsection = false, type, title, moduleId) {
    type = type || route;
    return {
      route,
      name: `admin-${type}`,
      moduleId: moduleId || `./pages/${type}/admin-${type}-page`,
      title: title || type,
      subsection: isSubsection,
      nav: true
    };
  }

  get pageSectionItems() {
    return this.router.navigation.filter(n => !n.config.subsection);
  }

  get pageSubSectionItems() {
    return this.router.navigation.filter(n => n.config.subsection);
  }
}
