import 'regenerator-runtime/runtime';
import * as environment from '../config/environment.json';
import * as Bluebird from 'bluebird';
import {
  PLATFORM
} from 'aurelia-pal';
import authService from 'services/authService';
// import { initialState } from './storeManager/state';

// remove out if you don't want a Promise polyfill (remove also from webpack.config.js)
Bluebird.config({
  warnings: {
    wForgottenReturn: false
  },
  longStackTraces: false
});

//webpack provide plugin is not working for ie11 ?
if (window && !window.Promise) {
  window.Promise = window.require('bluebird');
}

import {
  i18nConfig
} from 'configs';
import Backend from 'i18next-xhr-backend';
import 'intl';
import 'moment/locale/en-gb';
import 'flatpickr/dist/themes/material_blue.css';

import {
  ValidationMessageProvider
} from 'aurelia-validation';
import {
  I18N
} from 'aurelia-i18n';
import {
  InputValidationRenderer
} from 'components/input-validation-renderer';

import 'styles/index.scss';

export function configure(aurelia) {
  aurelia.use
    .standardConfiguration()
    .plugin(PLATFORM.moduleName('aurelia-i18n'), instance => {
      instance.i18next.use(Backend);
      //wait for tr file to load before continuing
      //as we dont want to have to force re-updating translations if file loads after app render.
      return instance.setup(i18nConfig).then(() => instance.setLocale('en'))
    })
    .plugin(PLATFORM.moduleName('aurelia-dialog'), cfg => {
      cfg.useDefaults();
      cfg.settings.overlayDismiss = true;
      cfg.settings.enableEscClose = true;
      cfg.settings.lock = false;
    })
    .plugin(PLATFORM.moduleName('aurelia-animator-css'))
    .plugin(PLATFORM.moduleName('aurelia-validation'))
    .plugin(PLATFORM.moduleName('@fonix/web-utils'))
    .plugin(PLATFORM.moduleName('@fonix/web-reports-viewer'))
    .globalResources(
      PLATFORM.moduleName('components/tcap'),
      PLATFORM.moduleName('components/simple-tabs/simple-tabs'),
      PLATFORM.moduleName('components/simple-tabs/simple-tab.html')
    );

  aurelia.use.developmentLogging(environment.debug ? 'debug' : 'warn');
  aurelia.use.plugin(PLATFORM.moduleName('aurelia-store'), { initialState: [] });

  if (environment.testing) {
    aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
  }

  //configure custom renderer
  aurelia.container.registerHandler('input-validation-renderer', container => {
    return container.get(InputValidationRenderer);
  });

  //translate validation messages
  const i18n = aurelia.container.get(I18N);
  ValidationMessageProvider.prototype.getMessage = function (key) {
    const translation = i18n.tr(`errormessages--${key}`);
    let parse = this.parser.parse || this.parser.parseMessage; //breaking change, sometimes one works, sometimes the other.
    return parse.call(this.parser, translation);
  };

  ValidationMessageProvider.prototype.getDisplayName = propertyName => {
    return i18n.tr(propertyName);
  };

  // Uncomment the line below to enable animation.
  // if the css animator is enabled, add swap-order="after" to all router-view elements

  // Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
  // aurelia.use.plugin(PLATFORM.moduleName('aurelia-html-import-template-loader'));

  //do not boot app if browser is not supported
  let supported = !window.__NOTSUPPORTED__;
  if (supported) {
    return aurelia.start().then(() => {
      let sharedPath = false;
      let getQS = key => {
        const location = window.location;
        let _qs = location.search;

        if (!_qs.length && location.hash && location.hash.indexOf('#/') >= 0) {
          _qs = window.location.hash;
          _qs = _qs.substring(_qs.indexOf('#/') + 2);
          _qs.substring(0, 6) === 'shared' && !!_qs.substring(7) ? sharedPath = true : sharedPath = false;
        }

        if (!_qs.length) return;

        try {

          let base64Decoded = window.atob(_qs)
          if (JSON.parse(base64Decoded)) {
            let obj = JSON.parse(base64Decoded);

            return obj[key];
          }
        } catch (e) {
          return (key == _qs) ? true : false;
        }
      };

      let checkURLAPIRequest = () => {
        if (getQS('version')) {
          document.open();
          document.write(__VERSION__);
          document.close();
          return true;
        }
        
        return false;
      }
      
      if (checkURLAPIRequest()) return;
      let root = sharedPath ? PLATFORM.moduleName('shells/shared/shared')
      : (authService.getToken())
      ? PLATFORM.moduleName('shells/app/app')
      : PLATFORM.moduleName('shells/landing/landing');
      aurelia.setRoot(root);
    });
  }
}
